import './style.css'
import {motion} from 'framer-motion';

const routeVariants = {
    initial: {
        opacity: 0,
        y: '50px',
    },
    final: {
        opacity: 1,
        y: '0px',
        transition: {
            duration: 0.5,
            delay: 0.5,
        },
    },
};

const Imprint = (props) => {
    // editing title for this page
    document.title = 'proviso - Impressum - Verantwortlich für den Inhalt';

    return (
        <motion.div variants={routeVariants}
                    initial="initial"
                    animate="final"
                    className="imprint">
            <h1>Impressum</h1>
            <h2>Verantwortlich für den Inhalt</h2>
            <hr/>
            <div class="margin-top">
                Markus Merten<br/>
                Geschäftsführer<br/>
                Tel: <a href="#"
                        onClick={(e) => (window.open('tel:+4916090295601'))}>
                +49 160 90295601
            </a>&nbsp;
                <br/>
                <b>
                    <a href="#"
                       onClick={(e) => (window.location = 'mailto:' + atob('aW5mb0Bwcm92aXNvLWdtYmguZGU=') + '?subject=Anfrage')}
                       title="Kontakt">
                        info(at)proviso-gmbh.de
                    </a>
                </b>

                <br/>
                <br/>
                <b>proviso GmbH</b>
                <br/>
                Ubbelohdestr. 2 <b>I</b> 35037 Marburg<br/>
                Tel: +49 160 90295601<br/>
                www.proviso-gmbh.de<br/>
                Amtsgericht Marburg I HRB 8462<br/>
            </div>
        </motion.div>
    );
};

export default Imprint;
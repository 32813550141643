import React from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';

import Home from '../Home';
import Imprint from '../Imprint';
import Contacts from '../Contacts';
import NotFound from '../NotFound';

import './style.css';


const Body = (props) => {
    const location = useLocation();

    return (
        <div className={'body'}>
            <AnimatePresence>
                <Routes location={location} key={location.pathname}>
                    <Route exact path="/" element={<Home/>}/>
                    <Route path="/kontakte" element={<Contacts/>}/>
                    <Route path="/impressum" element={<Imprint/>}/>
                    <Route exact path="*" element={<NotFound/>}/>
                </Routes>
            </AnimatePresence>
        </div>
    );
};

export default Body;
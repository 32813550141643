import './style.css'
import {motion} from 'framer-motion';

const routeVariants = {
    initial: {
        opacity: 0,
        y: '50px',
    },
    final: {
        opacity: 1,
        y: '0px',
        transition: {
            duration: 0.5,
            delay: 0.5,
        },
    },
};

const Home = (props) => {
    // editing title for this page
    document.title = 'proviso - 404 - Seite nicht gefunden';

    return (
        <motion.div variants={routeVariants}
                    initial="initial"
                    animate="final"
                    className="container">
            <h1>404</h1>
            <h2>Seite nicht gefunden</h2>
            <hr/>
        </motion.div>
    );
};

export default Home;
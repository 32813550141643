import { useNavigate  } from 'react-router-dom';

import './style.css'

const Footer = (props) => {
    const navigate = useNavigate();

    return (
        <div className="footer">
            <a onClick={() => (navigate('/'))}>Home</a>
            <a onClick={() => (navigate('/kontakte'))}>Kontakte</a>
            <a onClick={() => (navigate('/impressum'))}>Impressum</a>
        </div>
    );
};

export default Footer;
import point from '../../assets/proviso200.png';
import './style.css'

import Grid from '@mui/material/Grid'
import {motion} from 'framer-motion';

const routeVariants = {
    initial: {
        opacity: 0,
        y: '50px',
    },
    final: {
        opacity: 1,
        y: '0px',
        transition: {
            duration: 0.5,
            delay: 0.5,
        },
    },
};

const CONTACTS = [
    {
        name: 'Prof. Dr.-Ing. Markus Blatt',
        description: 'Professor für Massivbau und Baustofftechnologie <span style="display:inline-block;">Gründer FACTOR E<span/> Ingenieursdienstleistungs-GmbH',
        image: '/portraits/PortraitMarBla.jpg'
    },
    {
        name: 'Andi Droste',
        description: 'Sozialarbeiter, Business Coach IHK, Führungskraft in sozialem Unternehmen',
        image: '/portraits/PortraitAndDro.jpg'
    },
    {
        name: 'Paco Leuschner',
        description: 'Führungskraft in sozialem Unternehmen, Diakon',
        image: '/portraits/PortraitPacLeu.jpg'
    },
    {
        name: 'Markus Merten',
        description: 'Geschäftsführer proviso GmbH',
        image: '/portraits/PortraitMarMer.jpg',
        email: 'markus.merten(at)proviso-gmbh.de',
        phone: '+ 49 160 90295601'
    }
];

const Avatar = (props) => {
    return (<div className={'contact-avatar-container'} title={props.alt || ''}>
        <img alt={''} className={'contact-decoration'} src={point}/>
        <img className={'contact-avatar'} alt={props.alt || ''} src={props.src}/>
    </div>);
};

const Contact = (props) => {
    const contact = props.contact;

    return <Grid container item key={props.index + ''}
                 className={'contact-container'}
                 xs={12} md={6} lg={6}>
        <Grid item key={props.index + '_avatar'} xs={12} md={4} lg={4}>
            <Avatar src={contact.image} alt={'Photo von ' + contact.name}/>
        </Grid>
        <Grid item key={props.index + '_info'} xs={12} md={8} lg={8}
              className={'contact-info'}>
            <p className={'contact-name'}>{contact.name}</p>
            <p className={'contact-description'} dangerouslySetInnerHTML={{__html: contact.description}}/>
            <p className={'contact-email'}
               onClick={(e) => (window.location = 'mailto:' + contact.email?.replace('(at)', '@') + '?subject=Anfrage')}>
                {contact.email}
            </p>
            <p className={'contact-phone'}
               onClick={(e) => (window.open('tel:' + contact.phone?.replaceAll(' ', '')))}>
                {contact.phone}
            </p>
        </Grid>
    </Grid>
};

const Contacts = (props) => {
    // editing title for this page
    document.title = 'proviso - Wer wir sind - Mit diesen Menschen hat alles begonnen';

    return (
        <motion.div variants={routeVariants}
                    initial="initial"
                    animate="final"
                    className="contacts">
            <h1>Wer wir sind</h1>
            <h2>Mit diesen Menschen hat alles begonnen</h2>
            <hr/>
            <div  class="margin-top">
                <Grid container spacing={4}>
                    {CONTACTS
                        .map((contact, index) => (<Contact contact={contact} key={index} index={index}/>))}
                </Grid>
            </div>
        </motion.div>
    );
};

export default Contacts;
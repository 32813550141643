import './style.css'

import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {motion} from 'framer-motion';

const routeVariants = {
    initial: {
        opacity: 0,
        y: '50px',
    },
    final: {
        opacity: 1,
        y: '0px',
        transition: {
            duration: 0.5,
            delay: 0.5,
        },
    },
};

const ColorButton = styled(Button)(({theme}) => ({
    // color: theme.palette.getContrastText('white'),
    backgroundColor: '#2e4379',
    '&:hover': {
        backgroundColor: '#ACD7CB',
    },
}));

const Home = (props) => {
    // editing title for this page
    document.title = 'proviso - Ideen für Menschen und Gebäude';

    const onClick = () => {
        // const url = '/files/proviso_GmbH.pdf';
        // const link = document.createElement('a');
        // link.target = '_blank';
        // link.href = url;
        // link.download = 'preferred-filename.file-extension';
        // link.click();
        // link.remove();
    };
    return (
        <motion.div variants={routeVariants}
                    initial="initial"
                    animate="final"
                    className="container">
            <h1>Home</h1>
            <h2>
                Diese Internetseite befindet sich noch im Aufbau.
            </h2>
            <hr/>
            <div  class="margin-top">
                <ColorButton variant="contained" startIcon={<FileDownloadIcon/>}
                             onClick={onClick}
                             target={'_blank'}
                             rel="icon"
                             href={'/files/proviso_GmbH.pdf'}>
                    Firmenpräsentation herunterladen
                </ColorButton>
            </div>
        </motion.div>
    );
};

export default Home;
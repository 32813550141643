import logo from '../../assets/proviso_transparent.png';
import './style.css'

import { useNavigate  } from 'react-router-dom';

const Header = (props) => {
    const navigate = useNavigate();
    return (
        <header className="header" onClick={() => (navigate('/'))}>
            <img src={logo} className="logo" alt="proviso GmbH logo"/>
        </header>
    );
};

export default Header;